import React from "react"
import PropTypes from "prop-types"
import SEO from "@components/seo"
import Layout from "@layout"
import PageBreadcrumb from "../components/pagebreadcrumb"
import { graphql } from "gatsby"
import { normalizedData } from "@utils/functions"
import BlogRightSidebarArea from "../container/blog/blog-right-sidebar"

const BlogRightSidebarPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || [])
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"]
            }}
        >
            <SEO title="Blog Right Sidebar" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Blog Right Sidebar"
            />
            <BlogRightSidebarArea
                data={{
                    items: data.latestPosts.nodes
                }}
            />
        </Layout>
    )
}

BlogRightSidebarPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        latestPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({}))
        })
    })
}

export const query = graphql`
    query BlogRightSidebarPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        latestPosts: allArticle(
            sort: { fields: postedAt___date, order: DESC }
        ) {
            nodes {
                ...Articles
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                height: 200
                                width: 200
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    }
`

export default BlogRightSidebarPage
